<template>
  <div class="examPack">
    <div class="list" v-if="roomList && roomList.length > 0">
      <div class="item" v-for="(item, index) in roomList" :key="index" @click="tabId = item.id">
        <img class="item-image" src="@/assets/img/study/pager.png" />
        <div class="item-content flex-c" :class="item.seeNum && !isPurchase ? 'shiting-title' : ''">
          <div class="item-title ellipsis">
            {{ item.sjName }}
          </div>
        </div>

        <div v-if="item.paperPrice > 0 && item.status == 2" class="keep-learn" @click.stop="goOrder(item, 12)">
          立即购买
        </div>
        <div v-if="item.status == 1 || !item.paperPrice || item.paperPrice == 0" class="keep-learn keep-check"
          @click="checkClick(item)">
          开始答题
        </div>
      </div>
    </div>
    <div class="nothing" v-if="!roomList || roomList.length == 0">
      <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
      <div class="nothing-text">暂无内容哦~</div>
    </div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { Know } from '@/api/know'
let know = new Know()
import { getInfo, getToken } from '@/api/cookies'
export default {
  data() {
    return { userInfo: null, roomList: [], dialogVisible: false }
  },
  props: {
    isPurchase: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
    },
    type: {
      type: Number /* 1 直播 2录播 3音频 4 图文 5面授 */,
    },
  },
  created() {
    this.userInfo = getInfo()
    if (!this.id) {
      return
    }
    know.getPaperByCourse(undefined, this.type, this.id ? this.id : undefined, undefined, 3).then((res) => {
      this.roomList = res.data.array
    })
  },
  methods: {
    /* 下载 */
    checkClick(item) {
      let userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }
      if (this.isPurchase || item.seeNum) {
        let type = this.type
        switch (this.type) {
          case 1:
            type = 2
            break
          case 2:
            type = 3
            break
          case 3:
            type = 7
            break
          case 4:
            type = 8
            break
          case 5:
            type = 10
            break
          default:
            break
        }
        this.$router.push(
          `/quesTion/examDetails?title=${item.sjName}&paperId=${item.cContactid}&examType=2&type=${type}&courseId=${this.id}&IsPurchase=${this.isPurchase}`
        )
      } else {
        this.$notify({
          title: '错误',
          message: '未购买，无法学习, 请先去购买',
          type: 'error',
          duration: 2000,
        })
      }
    },
  },
}
</script>
<style lang="less" scoped>
/* 试卷列表 */
.examPack {
  .list {
    width: 100%;
    padding: 0px 32px;
    background: #ffffff;

    .item {
      position: relative;
      padding: 32px 0px;
      border-bottom: 1px solid #eee;
      display: flex;
      align-items: center;

      .item-image {
        width: 60px;
        height: 80px;
        flex-shrink: 0;
        background: #f7f8f9;
        margin-right: 12px;
      }

      .item-content {
        width: calc(100% - 170px);

        &.shiting-title::after {
          content: "可试看";
          width: 48px;
          height: 20px;
          color: #ff5e51;
          background-color: #ffedeb;
          border-radius: 2px;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          margin-left: 20px;
        }

        .item-title {
          font-size: 16px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
          max-width: calc(100% - 80px);
        }
      }

      .keep-learn {
        flex-shrink: 0;
      }
    }
  }

  /* 试kan */
  .audition {
    width: 48px;
    height: 20px;
    background: #ffedeb;
    border-radius: 2px 2px 2px 2px;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    color: #ff5e51;
    line-height: 20px;
    margin-left: 8px;
    text-align: center;
    flex-shrink: 0;
  }
}
</style>
